@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

body, HTML {
     font-family: 'Quicksand', sans-serif;
     font-size: 0.95rem;
}

#masthead {
     z-index:555;
}
 #masthead nav {
     padding-left: 0;
     padding-right: 0;
     box-shadow: none;
}
 #masthead .navbar-nav > li > a {
     color: #cdbfe3;
     padding: 0.5rem;
     font-weight: 500;
     font-size: 0.9rem;
}
 #masthead .navbar-nav > li > a:hover, #masthead .navbar-nav > li.current_page_item > a {
     color: #fff;
     font-weight: 600;
    /*background: #f9f9f9;
    */
}
 .coloredLogo, .whiteLogo {
     width:80%;
}
 #menu-main-menu > li:last-child > a {
     background:#007bff;
     color:#FFF !important;
     margin-left: 20px;
     border-radius:20px;
}
 #menu-main-menu > li > a {
     padding-left: 20px !important;
     padding-right: 20px !important;
     text-transform:uppercase;
     color:#333 !important;
}
 #menu-main-menu > li > a:hover {
     color:#333 !important;
     font-weight:normal !important;
}
 #menu-main-menu > li.active > a:hover {
     font-weight:600 !important;
}
 #menu-main-menu > li.active > a {
     text-decoration:underline;
}
 #menu-main-menu > li:last-child > a {
     text-align:center;
}
 #menu-main-menu > li:last-child > a:hover {
     color:#FFF !important;
}

.tranHeader {
    background:none !important;
     box-shadow: none !important;
}

 .tranHeader .whiteLogo{
    display:block !important;
}
 .tranHeader .coloredLogo {
    display:none;
}

.tranHeader #menu-main-menu li .nav-link {
    color:#FFF !important;
}

 .whiteHeader {
     background:#FFF !important;
     box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
}
 .whiteHeader .whiteLogo {
    display:none;
}
 .whiteHeader .coloredLogo {
    display:block;
}
 .whiteHeader #menu-main-menu li a {
     color:#333 !important;
}
 .whiteHeader #menu-main-menu > li:last-child a {
     color:#FFF !important;
}
 .dropdown-item {
     font-weight: 300;
     color: #333333;
     padding: 0.6em 1.6em;
     opacity: 0.8;
     font-size: 0.85em;
}
 .dropdown-item:focus, .dropdown-item:hover {
     color: #111111;
     opacity: 1;
     background-color: #ffffff;
}
 .nav-item i {
     color: #333333;
     margin-right: 10px !important;
}
 .srv-validation-message {
     color: red;
}
 .w-40{
     width: 40%;
}
 .whitebtn {
     padding: 12px 40px;
     border: 2px solid #FFFFFF;
     color: #FFFFFF;
     font-size: larger;
     text-transform: uppercase;
     font-weight:bold;
}
 .whitebtn:hover {
     background:#FFF;
     color:#007bff;
}
.bottom-btn{
	    box-sizing: border-box;
    font-size: 1.3em;
    padding: 1em 2em;
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff !important;
    border-radius: 1.5em;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.bd-redius-1rem{
	border-radius: 1rem;
}

.border-L {
    border: 1px solid #dee2e6!important;
}

.leftBx {
	padding:2rem 0;
	font-size:1.5rem;
	color:#007bff;
}
 .leftBx p{
     line-height:36px;
}
 .h-sertab h5 {
     font-size:1rem;
     line-height:24px;
}
 .cointSteps {
     background:#007bff;
     width:80px;
     height:80px;
     text-align:center;
     line-height:80px;
     color:#FFF;
     font-weight:bold;
     font-size:2.6rem;
     position:absolute;
     top:-35px;
     left:0px;
     right:0px;
     margin:auto;
}
 .fs-1 {
     font-size:0.9rem;
     line-height:24px;
     font-weight:400;
}
 .testimo h3{
     font-size:2rem;
     margin-bottom:3rem;
}
 .testimo .sow-testimonial-text {
     padding-top:0px !important;
}
 .testimo .sow-testimonial {
     border-radius:1rem;
}
 .s-link a {
     font-size: 1.25rem;
     display:inline-block;
     text-decoration:none;
     margin-right:1.5rem;
}
 .page-tagline {
     top:70px;
}
 .page-tagline h1{
     font-weight:bold;
     font-size:2rem;
}
 .entry-header h1.entry-title {
    display:none;
}
 .listBx {
     border-left-width:3px !important;
     background:#FFF;
     box-shadow: 0 0 5px #e5e5e5;
}
 .cmt-6 {
     margin-top:5rem !important;
}
 .carousel-caption h2{
     font-size:3rem !important;
}
 .h-sertab div .text-center, .custom-html-widget .bg-white.px-3.py-4.position-relative{
    border-radius:10px;
}
 .es-field-wrap {
     width:68%;
     display:inline-block;
     position:relative;
}
 .es_caption {
     padding-bottom:0.5rem;
}
 .es-field-wrap .es_txt_email {
     position:absolute;
     top:-10px;
     left:0px;
     width:100%;
     height:40px;
     background:#f7f7f7;
     border:solid 2px #CCCCCC;
     outline:none;
     box-shadow:none;
     padding:5px 15px;
     border-radius:5px;
}
 .es_subscription_form_submit {
     width:30%;
     display:inline-block;
     background:#999;
     color:#FFF;
     height:40px;
     padding:5px 20px;
     border:none;
     border-radius:5px;
}
 .full-rounded {
     border-radius:30px;
}
 .carousel-caption{
     top:0;
}
 .captop{
     position: absolute;
     top: -85px;
}
 .bg-image {
     object-fit: cover;
     font-family: 'object-fit: cover;';
     height:100vh 
}
 .blog .site-content, .single-post .site-content{
     padding-top:10rem !important;
}
 article.type-post, .single-post .site-content .type-post {
     padding:1.5rem;
     background:#FFF;
     box-shadow:0 0 4px #DDD;
     border-radius:5px;
}
 .more-link {
     display:block;
     margin-top:1rem;
     clear:both;
}
 .single-post .entry-header h1.entry-title {
    display:block !important;
     font-size:2rem !important;
}
 .nav-link {
     padding-left:0px;
}
 .nav-link:hover {
     background:none;
}
 .blog, .single-post {
     background:#f2f2f2 url(/wp-content/uploads/2019/03/vetBanner.jpg) top center no-repeat;
}
 #secondary {
     padding-left:2.5rem;
}
 .sow-testimonial-user {
     color:#FFF;
     padding-bottom:10px;
}
 .sow-testimonials {
     display: flex;
}
 .sow-testimonial {
     height:100%;
}
 .img-circle {
    border-radius:50% !important;
}
 .entry-header {
    display:none;
}
 .entry-content {
    margin-top:0px;
}
 #content.site-content {
    padding-top:0px;
}
 body {
    overflow-x:hidden;
}
 .carousel-caption h2 {
     font-size:3.5rem;
}
 .tranHeader {
    background:none !important;
     box-shadow: none !important;
}
 .divider-text {
     position: relative;
     text-align: center;
     margin-top: 15px;
     margin-bottom: 15px;
}
 .divider-text span {
     padding: 7px;
     font-size: 12px;
     position: relative;
     z-index: 2;
}
 .divider-text:after {
     content: "";
     position: absolute;
     width: 100%;
     border-bottom: 1px solid #ddd;
     top: 55%;
     left: 0;
     z-index: 1;
}
 .btn-facebook {
     background-color: #405D9D;
     color: #fff;
}
 .btn-google {
     background-color: #C94131;
     color: #fff;
}
 .bannerContent {
     position: absolute;
     top: 200px;
     width: 100%;
}
 .bannerContent h2 {
     color: #FFF;
     font-size: 3rem !important;
     text-align: center;
}
 .bannerContent .serviceBTN {
     position: relative;
}
 .border-bottom-3{
     border-bottom: solid 3px #007bff;
}
 .vetListPic {
     width: 80px;
}
 .badge-c {
     background: #e8e8e8;
     padding: .4em .8em;
     color: #666;
}
 .summary li {
     padding: 0.4rem 0;
}
 .checkout li{
     padding: 0.6rem 0;
     border-top: 1px dotted #DDD;
     position: relative;
     padding-right: 90px;
}
 .checkout li .price {
     width: 80px;
     position: absolute;
     top: 11px;
     right: 0;
     text-align: right;
     display: block;
}
 .checkout .total {
     font-size: 1rem;
     color: #007bff;
}
 .form_title h4 strong {
     background-color: #007bff;
     text-align: center;
     width: 40px;
     height: 40px;
     display: inline-block;
     border-radius: 50%;
     color: #fff;
     font-size: 18px;
     font-size: 1.125rem;
     line-height: 42px;
     margin-right: 1rem;
}
/* The container */
 .c-checkbox {
     display: block;
     position: relative;
     padding-left: 35px;
     margin-bottom: 0px;
     cursor: pointer;
     height: 25px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     line-height: 25px;
}
/* Hide the browser's default checkbox */
 .c-checkbox input {
     position: absolute;
     opacity: 0;
     cursor: pointer;
     height: 0;
     width: 0;
}
/* Create a custom checkbox */
 .checkmark {
     position: absolute;
     top: 0;
     left: 0;
     height: 25px;
     width: 25px;
     background-color: #eee;
}
/* On mouse-over, add a grey background color */
 .c-checkbox:hover input ~ .checkmark {
     background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
 .c-checkbox input:checked ~ .checkmark {
     background-color: #2196F3;
}
/* Create the checkmark/indicator (hidden when not checked) */
 .checkmark:after {
     content: "";
     position: absolute;
     display: none;
}
/* Show the checkmark when checked */
 .c-checkbox input:checked ~ .checkmark:after {
     display: block;
}
/* Style the checkmark/indicator */
 .c-checkbox .checkbtn:after {
     left: 9px;
     top: 5px;
     width: 6px;
     height: 10px;
     border: solid white;
     border-width: 0 3px 3px 0;
     transform: rotate(45deg);
}
/* Style the indicator (dot/circle) */
 .c-checkbox .rediobtn:after {
     top: 9px;
     left: 9px;
     width: 8px;
     height: 8px;
     border-radius: 50%;
     background: white;
}

 @media (max-width: 767px) {
    .page-tagline {
        position:static !important;
        background:#666;
   }
    .pageTitle img {
        display:none;
   }
    .page-tagline h1 {
        width:100% !important;
        padding-top:60px !important;
        padding-left:20px;
        padding-right:20px;
   }
     .sow-testimonials {
         display:block;
    }
}
 @media (max-width: 450px) {
     .carousel-caption h2{
         font-size:2.5rem !important;
    }
     .captop{
         top: -150px !important;
    }
     #secondary {
         padding-left:15px;
         margin-top:0;
    }
     #menu-main-menu > li:last-child > a {
         margin:20px auto;
    }
     #stickySidebar .sidebar__inner{
         transform:none !important;
         position: static !important;
    }
}
 @media (min-width: 768px) {
     #stickySidebar{
         will-change: min-height;
    }
     #stickySidebar .sidebar__inner{
         position: relative;
         transform: translate(0, 0);
         transform: translate3d(0, 0, 0);
         will-change: position, transform;
    }
}
 @media (min-width: 992px) {
     .bannerContent .serviceBTN::after{
         position: absolute;
         right: -1px;
         bottom: 38px;
         width: 1px;
         height: 25px;
         background: #cac6c6;
         content: "";
    }
     .bannerContent .serviceBTN.last::after{
         display: none;
    }
     .vetListPic {
         width: 150px;
    }
     .vetlist .media-body{
         padding-right: 220px;
         position: relative;
    }
     .bookAppoBTN {
         float: right;
         width: 200px;
    }
     .bookAppoBTN .btn {
        display: block;
    }
}
 
.whiteHeaderOffset {
    margin-top: 8em;
}

/* TIME Slot section */
.sch-cal-blk {
    padding: 1em 0;
}

.sch-cal-lbtn, .sch-cal-rbtn {
    display: inline-flex;
    align-items: center;
}

.sch-cal-link {
    border: 1px solid gray;
    padding: 0.7em 0.9em;
    border-radius: 50%;
    margin: 0 1em;
}

.sch-cal-link:hover {
    color: white;
    background: #007BFF;
}

.sch-cal-days {
    display: block;
}

.sch-cal-days-ul {
    list-style-type: none;
    display: inline-flex;
    padding: 0;
}

.sch-cal-days-ul-li {
    padding: 0 1rem;
    cursor: pointer;
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
}

.sch-cal-days-ul-li.sel {
    font-weight: 600;
    border-bottom: 3px solid #007BFF;
    padding-bottom: 5px;
}

.sch-time-blk {
    padding: 0 0.8em;
}

.sch-cal-days-ul, .sch-cal-time-ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    text-align: center;
}

.sch-cal-time-ul li{
    float: left;
    width: 20%;
}

.sch-cal-time-ul li label {
    transition: all 0.3s ease-in-out;
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 8px 15px;
    min-width: 100px;
    margin: 6px;
    cursor: pointer;
    color: cornflowerblue;
}

.sch-cal-time-ul li label:hover, 
.sch-cal-time-ul li input[type="checkbox"]:checked + label,
.sch-cal-time-ul li input[type="radio"]:checked + label {
    background-color: #9CC400;
    color: #fff;
}

.sch-cal-time-ul li input[type="checkbox"],
.sch-cal-time-ul li input[type="radio"] {
    display: none;
}

/* Vet listing */
.vet-listing-blk {
    transition: all 0.5s ease-in-out;
    margin: 10px 20px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;   
    padding: 1em;
}

.vet-listing-col-1 {
    padding: 0;
}

.vet-listing-col-2 {
    margin-left: 1em;
    align-self: center;
}

.vet-listing-col-3 {
    margin-left: auto;
    align-self: flex-end;
}

.vet-listing-title {
    font-size: 1.82em;
    color: #007BFF;
    font-weight: 300;
}

.vet-listing-blk p {
    margin-bottom: 0.75rem;
}

.vet-listing-title span {
    font-size: 0.8em;
    color: #666;
    margin-left: 0.5em;
}

.vet-listing-fees {
    font-size: 1.2em;
    color: green,
}

.vet-listing-tags span {
    font-size: 0.85em;
    color: #111;
    background: #eee;
    font-weight: 300;
    margin-right: 5px;
    padding: 5px 8px;
}

.vet-list-slots {
    width: 80%;
    margin: 0 auto;
}

/* Checkout Steps tracker */
ol.progtrckr {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    border-bottom: 2px solid #9CC400;
}

ol.progtrckr li.progtrckr-todo {
    border-bottom: 2px dashed #9CC400;
}

ol.progtrckr li.progtrckr-todo i {
    display: none;
}

ol.progtrckr li span {
    padding: 0 1.5rem;
}

@media (max-width: 650px) {
    .progtrckr li span {
        display: none;
    }
}

ol.progtrckr li i {
    position: relative;
    bottom: -2.4rem;
    left: 50%;
    background: #9CC400;
    color: white;
    padding: .5em;
    border-radius: 50%;
    font-size: 1em;
}


/* */

.icon-order-success svg path {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
}


@media (max-width: 450px) {

    .tranHeader #menu-main-menu li .nav-link {
        color:#333 !important;
   }

   #main-nav {
        background-color: #fff!important;
        border-radius: .25rem!important;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        padding: 20px;
   }
   #main-nav li {
       margin-bottom: 10px;
    }
}


.react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
    background-color: #f8f9fa!important;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
:root {
    --nav-bar-height: 62px;
    --side-menu-width: 280px; 
    --color-primary: #0667B8;
    --blue-bright: #007BFF;
    --color-secondary: #9CC400;
    --color-tertiary: #FFCF00;
}

body {
  font-size: 14px;
}

.text-primary {
    color: #0667B8;
    color: var(--color-primary);
}

.text-secondary {
    color: #9CC400;
    color: var(--color-secondary);
}

.text-tertiary {
    color: #FFCF00;
    color: var(--color-tertiary);
}

/* start for top Header */
nav {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
}

.menu-btnSm {
  background: none;
  border: 0;
}

.menu-btnSm i {
  font-size: 28px;
}

.be-top-header {
  background-color: #fff;
  border: 0;
  margin-bottom: 0;
  padding: 0rem 1rem;
}

.navbar {
    height: 62px;
    height: var(--nav-bar-height);
}

.navbar-brand img {
    width: 160px;
}

.nav-item  i {
    vertical-align: bottom;
    margin-right: 5px;
}

.navIcons > li > a {
  color: #007BFF;
  color: var(--blue-bright);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  padding-right: 0.9rem !important;
  padding-left: 0.9rem !important;
}

.navIcons > li > a:hover {
  background-color: #eeeeee;
}

.navbar-expand>.container, .navbar-expand>.container-fluid {
  padding-left: 15px; 
}

.badgeNoti {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #ef6c00;
  color: #fff;
  font-size: 11px;
  top: 12px;
  left: 25px;
  border-radius: 3px;
  text-align: center;
  line-height: 18px;
  box-shadow: -1px 1px 0px #fff;
  font-weight: 600;
  overflow: hidden;
}

/* End for top Header */

.mainc {
  float: left;
  height: calc(100vh - 62px);
  height: calc(100vh - var(--nav-bar-height));
  width: 100%;
  overflow: auto;
  padding-top: 20px;
}

.be-left-sidebar {
  min-height: calc(100vh - 62px);
  min-height: calc(100vh - var(--nav-bar-height));
  width: 280px;
  width: var(--side-menu-width);
  margin-top: 0px;
  padding-bottom: 61px;
  position: fixed;
	left: 0;
	z-index: 10;
  display: none;
  background: #0667B8;
  background: var(--color-primary);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
}

.be-left-sidebar .userbx {
  padding: 15px;
}
.be-left-sidebar .userbx a > div {
  padding: 5px 0 0 15px;
}


.be-left-sidebar .sidebar-elements > li > a {
  display: block;
  padding: 0 15px;
  color: #fafafa;
  font-size: 1.077em;
  line-height: 56px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
}

.be-left-sidebar .sidebar-elements > li > a:hover {
    background-color: #0775d2;
}

.be-left-sidebar .sidebar-elements > li > a.active {
    color: #FFCF00;
    color: var(--color-tertiary);
}
    
.be-left-sidebar .sidebar-elements > li > a > i {
  vertical-align: middle;
  padding-right: 20px;
  line-height: 0;
  font-size: 26px;
}

.footer {
  position: fixed;
  width: 280px;
  width: var(--side-menu-width);
  bottom: 5px;  
  border-top: solid 1px #025499;
  padding: 10px 15px;
  color: #333333;
  font-size: 12px;
  margin-top: 10px;
}

.appBox {    
  transition: all 0.5s ease-in-out;
  margin-bottom: 10px;
  box-shadow: 0 .1rem .2rem #eeeeee!important;
  padding: 0.5em;
  border: 1px solid #fafafa;
}

.appBox img {    
  width: 120px;
}

.appBox p {    
  margin-bottom: 0.5rem;
}

.details-tab {
  padding: 10px 60px;
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 680px) { 
	.be-left-sidebar {
		display: block;
		float: left;
		position: static;
  }
  
  .mainc {
    padding: 20px;
    width: calc(100% - 280px);
    width: calc(100% - var(--side-menu-width));
  }
}



