:root {
    --nav-bar-height: 62px;
    --side-menu-width: 280px; 
    --color-primary: #0667B8;
    --blue-bright: #007BFF;
    --color-secondary: #9CC400;
    --color-tertiary: #FFCF00;
}

body {
  font-size: 14px;
}

.text-primary {
    color: var(--color-primary);
}

.text-secondary {
    color: var(--color-secondary);
}

.text-tertiary {
    color: var(--color-tertiary);
}

/* start for top Header */
nav {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
}

.menu-btnSm {
  background: none;
  border: 0;
}

.menu-btnSm i {
  font-size: 28px;
}

.be-top-header {
  background-color: #fff;
  border: 0;
  margin-bottom: 0;
  padding: 0rem 1rem;
}

.navbar {
    height: var(--nav-bar-height);
}

.navbar-brand img {
    width: 160px;
}

.nav-item  i {
    vertical-align: bottom;
    margin-right: 5px;
}

.navIcons > li > a {
  color: var(--blue-bright);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  padding-right: 0.9rem !important;
  padding-left: 0.9rem !important;
}

.navIcons > li > a:hover {
  background-color: #eeeeee;
}

.navbar-expand>.container, .navbar-expand>.container-fluid {
  padding-left: 15px; 
}

.badgeNoti {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #ef6c00;
  color: #fff;
  font-size: 11px;
  top: 12px;
  left: 25px;
  border-radius: 3px;
  text-align: center;
  line-height: 18px;
  box-shadow: -1px 1px 0px #fff;
  font-weight: 600;
  overflow: hidden;
}

/* End for top Header */

.mainc {
  float: left;
  height: calc(100vh - var(--nav-bar-height));
  width: 100%;
  overflow: auto;
  padding-top: 20px;
}

.be-left-sidebar {
  min-height: calc(100vh - var(--nav-bar-height));
  width: var(--side-menu-width);
  margin-top: 0px;
  padding-bottom: 61px;
  position: fixed;
	left: 0;
	z-index: 10;
  display: none;
  background: var(--color-primary);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
}

.be-left-sidebar .userbx {
  padding: 15px;
}
.be-left-sidebar .userbx a > div {
  padding: 5px 0 0 15px;
}


.be-left-sidebar .sidebar-elements > li > a {
  display: block;
  padding: 0 15px;
  color: #fafafa;
  font-size: 1.077em;
  line-height: 56px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
}

.be-left-sidebar .sidebar-elements > li > a:hover {
    background-color: #0775d2;
}

.be-left-sidebar .sidebar-elements > li > a.active {
    color: var(--color-tertiary);
}
    
.be-left-sidebar .sidebar-elements > li > a > i {
  vertical-align: middle;
  padding-right: 20px;
  line-height: 0;
  font-size: 26px;
}

.footer {
  position: fixed;
  width: var(--side-menu-width);
  bottom: 5px;  
  border-top: solid 1px #025499;
  padding: 10px 15px;
  color: #333333;
  font-size: 12px;
  margin-top: 10px;
}

.appBox {    
  transition: all 0.5s ease-in-out;
  margin-bottom: 10px;
  box-shadow: 0 .1rem .2rem #eeeeee!important;
  padding: 0.5em;
  border: 1px solid #fafafa;
}

.appBox img {    
  width: 120px;
}

.appBox p {    
  margin-bottom: 0.5rem;
}

.details-tab {
  padding: 10px 60px;
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 680px) { 
	.be-left-sidebar {
		display: block;
		float: left;
		position: static;
  }
  
  .mainc {
    padding: 20px;
    width: calc(100% - var(--side-menu-width));
  }
}
